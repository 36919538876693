import {Component, OnInit} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {routerFade} from './animations/page-fade.animation';
import {submenuConfig} from './config/submenu.config';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [routerFade]
})
export class AppComponent implements OnInit {

    submenu = submenuConfig;

    constructor() {

    }

    ngOnInit(): void {
    }


    prepareRoute(outlet: RouterOutlet) {
        return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
    }
}
