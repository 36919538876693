import {Component, ElementRef, HostListener, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import * as Highcharts from 'highcharts';
import {Subject} from 'rxjs';
import * as moment from 'moment';
import {diagramColors, DiagramConfig} from '../config/diagram.config';

@Component({
    selector: 'app-diagram',
    templateUrl: './diagram.component.html',
    styleUrls: ['./diagram.component.scss']
})
export class DiagramComponent implements OnInit, OnChanges {

    @Input('data') data = null;
    @Input('config') config: DiagramConfig = null;

    @ViewChild('chartContainer', {static: true}) container: ElementRef;

    Highcharts: typeof Highcharts = Highcharts;
    chart: Highcharts.Chart = null;
    diagramOptions: Highcharts.Options = null;
    diagramReady = new Subject();

    currentTime = null;
    xAxisZeroInserted = false;

    private containerWidth: number = null;
    private diagramHeight = 250;
    private colors = diagramColors;


    constructor() {
    }

    ngOnInit(): void {
        this.currentTime = moment().hour(17).minute(15).second(0).millisecond(0).unix();

        this.setupBaseDiagram();
        if (this.data !== null && this.data !== undefined) {
            this.updateDiagram();
        }

        this.diagramReady.subscribe(() => {
            this.containerWidth = this.container.nativeElement.clientWidth;
            this.updateDiagramWidth();
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.data !== null && this.data !== undefined) {
            this.data = changes.data.currentValue;
            this.xAxisZeroInserted = false;
            this.updateDiagram();
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.containerWidth = this.container.nativeElement.clientWidth;
        this.updateDiagramWidth();
        this.alignLabels();
        this.alignZeroLine();
        this.alignNowLine();
    }

    onChartInstance(chart: Highcharts.Chart) {
        this.chart = chart;
        this.diagramReady.next();
    }

    private updateDiagram(): void {
        if (!this.chart) {
            return;
        }

        if (!this.xAxisZeroInserted) {
            const options = {
                xAxis: {
                    plotLines: [
                        {
                            name: 'x',
                            value: this.data[0].x - (7.5 * 60 * 1000),
                            width: 1,
                            color: this.colors.grid.grey,
                        },
                        {
                            name: 'now',
                            width: 2,
                            value: this.currentTime * 1000,
                            color: this.colors.blue,
                            zIndex: 10,
                        },
                    ]
                },
                yAxis: null
            };

            if (this.config.yAxis.plotLines.show) {
                options.yAxis = {
                    plotLines: [
                        {
                            name: 'green',
                            width: 1,
                            value: 270,
                            color: this.colors.green,
                            label: {
                                text: '> 270',
                                style: {
                                    fontFamily: 'Innogy regular',
                                    fontSize: '10',
                                    color: '#666'
                                },
                                textAlign: 'right'
                            }
                        },
                        {
                            name: 'red',
                            width: 1,
                            value: 450,
                            color: this.colors.red,
                            label: {
                                text: '> 450',
                                style: {
                                    fontFamily: 'Innogy regular',
                                    fontSize: '10',
                                    color: '#666'
                                },
                                textAlign: 'right'
                            }
                        },
                    ]
                };

            }

            this.chart.update(options, false);
            this.xAxisZeroInserted = true;
        }

        const options = {series: [{name: 'data', data: this.data}]};
        this.chart.update(options as Highcharts.Options, true);
        this.alignLabels();
        this.alignZeroLine();
        this.alignNowLine();

    }

    private updateDiagramWidth(): void {
        const options = {
            chart: {
                width: this.containerWidth
            }
        };
        this.chart.update(options);
    }

    private setupBaseDiagram(): void {
        this.diagramOptions = {
            chart: {
                type: 'column',
                height: this.diagramHeight + 28,
                width: this.containerWidth,
                // marginTop: this.config.xAxis.position === 'top' ? 28 : 0,
                // marginBottom: this.config.xAxis.position === 'top' ? 0 : 28,
                marginLeft: 80,
                animation: false,
            },
            title: {text: ''},
            xAxis: {
                type: 'datetime',
                title: {
                    enabled: true,
                    text: this.config.xAxis.title,
                },
                dateTimeLabelFormats: {
                    hour: '%H:%M',
                },
                offset: 3,
                tickInterval: 60 * 60 * 1000,
                tickColor: this.colors.grid.grey,
                endOnTick: true,
                grid: {
                    enabled: true,
                },
                plotLines: [],
                labels: {
                    formatter() {
                        if (this.isFirst) {
                            return '1:00';
                        }
                        if (this.isLast) {
                            return '';
                        }
                        const date = moment(this.value);
                        const min = +date.format('HH');
                        return `${min + 1}:00`;
                    },
                    autoRotation: false,
                    style: {
                        fontFamily: 'Innogy regular',
                        fontSize: '15',
                        color: this.colors.label.grey,
                    },
                    enabled: this.config.xAxis.show,
                    align: 'center'
                },
                opposite: this.config.xAxis.position === 'top'
            },
            yAxis: {
                title: {
                    text: this.config.yAxis.title,
                    offset: 50,
                    style: {
                        fontFamily: 'Innogy regular'
                    }
                },
                labels: {
                    enabled: true,
                    style: {
                        fontSize: '10px',
                        fontFamily: 'Innogy regular'
                    },
                    formatter() {
                        return this.value.toString();
                    },
                },
                tickAmount: 3,
                tickColor: this.colors.grid.grey,
                tickPositioner() {
                    this.tickPositions[0] = -1000;
                    return this.tickPositions;
                },
                tickmarkPlacement: 'on',
                endOnTick: false,
                startOnTick: false,
                reversed: this.config.xAxis.position === 'top',
            },
            legend: {
                enabled: false,
            },
            tooltip: {
                // valueSuffix: ' millions',
                enabled: false,
            },
            plotOptions: {
                column: {
                    color: this.colors.grey,
                    borderRadius: 3,
                    animation: {
                        duration: 350,
                    },
                    pointPlacement: 'between',
                },
                series: {
                    events: {
                        click: function(event) {
                            console.log(event.point.x, event.point.y);
                        }
                    }
                },
            },
            credits: {
                enabled: false
            },
            // time: {
            //     timezone: 'Europe/Berlin'
            // },
            series: [
                {
                    data: null,
                    type: 'column'
                }
            ]
        };

    }

    /**
     * Centers the labels below the following for data points.
     */
    private alignLabels(): void {
        const first = this.chart.xAxis[0].ticks[this.chart.xAxis[0].tickPositions[0]].mark.element as SVGPathElement;
        const second = this.chart.xAxis[0].ticks[this.chart.xAxis[0].tickPositions[1]].mark.element as SVGPathElement;
        const distance = second.getPointAtLength(0).x - first.getPointAtLength(0).x;
        const axis = this.chart.xAxis[0] as any;
        axis.labelGroup.element.childNodes.forEach((element: SVGTextElement) => {
            const current_x = +element.getAttribute('x');
            element.setAttribute('x', (current_x + (distance / 2)).toString());
        });
    }

    private alignZeroLine(): void {
        // move zero point down a few pixel
        const axis = this.chart.xAxis[0] as any;
        const line = axis.plotLinesAndBands.find(el => el.options.name === 'x');
        const element = line.svgElem.element as SVGPathElement;
        const transform_string = element.getAttribute('d');
        const transform = transform_string.split(' ') as Array<string>;
        transform[transform.length - 1] = '470';
        transform[1] = '93.5';
        transform[2] = this.config.xAxis.position === 'bottom' ? '-28' : '28';
        transform[4] = '93.5';
        element.setAttribute('d', transform.join(' '));
    }

    private alignNowLine(): void {
        const axis = this.chart.xAxis[0] as any;
        const line = axis.plotLinesAndBands.find(el => el.options.name === 'now');
        const element = line.svgElem.element as SVGPathElement;
        const transform_string = element.getAttribute('d');
        const transform = transform_string.split(' ') as Array<string>;
        console.log(transform);

        if (this.config.xAxis.position === 'bottom') {
            transform[2] = '0';
        } else if (this.config.xAxis.position === 'top') {
            transform[transform.length-1] = '1000';
        }
        element.setAttribute('d', transform.join(' '));
    }
}


