import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HeaderComponent} from './header/header.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FlexLayoutModule} from '@angular/flex-layout';
import {DashboardComponent} from './dashboard/dashboard.component';
import {SubmenuComponent} from './submenu/submenu.component';
import {HttpClientModule} from '@angular/common/http';
import {LottieModule} from 'ngx-lottie';
import {InlineSVGModule} from 'ng-inline-svg';
import player from 'lottie-web';
import {DetailComponent} from './detail/detail.component';
import {PowerSwitchComponent} from './power-switch/power-switch.component';
import {HighchartsChartModule} from 'highcharts-angular';
import {LiveDiagramComponent} from './live-diagram/live-diagram.component';
import {DiagramComponent} from './diagram/diagram.component';
import {CarDetailComponent} from './car-detail/car-detail.component';
import {HeatingDetailComponent} from './heating-detail/heating-detail.component';
import {DropdownComponent} from './dropdown/dropdown.component';
import {TemperaturePickerComponent} from './temperature-picker/temperature-picker.component';
import {TimespanPickerComponent} from './timespan-picker/timespan-picker.component';
import {SettingsComponent} from './settings/settings.component';
import {InformationTileComponent} from './information-tile/information-tile.component';
import {TimeSpinnerComponent} from './time-spinner/time-spinner.component';
import {TutorialComponent} from './tutorial/tutorial.component';
import {TooltipDirective} from './tooltip.directive';

// Note we need a separate function as it's required
// by the AOT compiler
export function playerFactory() {
    return player;
}

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        DashboardComponent,
        SubmenuComponent,
        DetailComponent,
        PowerSwitchComponent,
        LiveDiagramComponent,
        DiagramComponent,
        CarDetailComponent,
        HeatingDetailComponent,
        DropdownComponent,
        TemperaturePickerComponent,
        TimespanPickerComponent,
        SettingsComponent,
        InformationTileComponent,
        TimeSpinnerComponent,
        TutorialComponent,
        TooltipDirective
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        FlexLayoutModule,
        HttpClientModule,
        LottieModule.forRoot({player: playerFactory}),
        InlineSVGModule.forRoot({}),
        HighchartsChartModule,
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}
