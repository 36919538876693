import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'timespan-picker',
  templateUrl: './timespan-picker.component.html',
  styleUrls: ['./timespan-picker.component.scss'],
  animations: [
    trigger('colorFade', [
      state('on', style({
         color: '#ffffff',
         backgroundColor: '#009ba5',
      })),
      state('off', style({
         color: '#009ba5',
         backgroundColor: '#ffffff',
      })),
      transition('on => off', animate('200ms ease-out')),
      transition('off => on', animate('200ms ease-in'))
    ])
  ]
})

export class TimespanPickerComponent implements OnInit {
  @Input('startTime') startTime: number = 6;
  @Input('endTime') endTime: number = 22;
  @Input('step') step: number = 15;
  @Input('showSave') showSaveButton: boolean = false;
  @Input('titles') dropdownTitles: Array<string> = [ 'Beginn wählen', 'Ende wählen' ];

  @Output('times') _times = new EventEmitter<Array<{ title: string, time: { hours: number, minutes: number} }>>();
  @Output('days') _days = new EventEmitter<Array<{ day: string, checked: boolean }>>();

  @Output('saved') saved = new EventEmitter();

  public times: Array<{ title: string, time: { hours: number, minutes: number} }> = [];
  public days: Array<{ day: string, checked: boolean }> = [
    {
      day: 'Mo',
      checked: false
    },
    {
      day: 'Di',
      checked: false
    },
    {
      day: 'Mi',
      checked: false
    },
    {
      day: 'Do',
      checked: false
    },
    {
      day: 'Fr',
      checked: false
    },
    {
      day: 'Sa',
      checked: false
    },
    {
      day: 'So',
      checked: false
    }
  ];

  constructor() { }

  ngOnInit() {
  }

  onDaySelected(index: number) {
    this.days[index].checked = !this.days[index].checked;

    this._days.emit(this.days)
  }

  onTimeSeleted(time: { hours: number, minutes: number }, index: number) {
    this.times[index] = { title: this.dropdownTitles[index], time: {hours: time.hours, minutes: time.minutes} };

    this._times.emit(this.times);
  }

}
