import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {DashboardComponent} from './dashboard/dashboard.component';
import {DetailComponent} from './detail/detail.component';
import {LiveDiagramComponent} from './live-diagram/live-diagram.component';
import {CarDetailComponent} from './car-detail/car-detail.component';
import {HeatingDetailComponent} from './heating-detail/heating-detail.component';
import {SettingsComponent} from './settings/settings.component';
import {TutorialComponent} from './tutorial/tutorial.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'tutorial',
        pathMatch: 'full'
    },
    {
        path: 'tutorial',
        component: TutorialComponent,
        data: {animation: 'Tutorial'}
    },
    {
        path: 'dashboard',
        component: DashboardComponent,
        data: {animation: 'Dashboard'}
    },
    {
        path: 'settings',
        component: SettingsComponent,
        data: {animation: 'Settings'}
    },
    {
        path: 'detail',
        component: DetailComponent,
        data: {animation: 'Detail'},
    },
    {
        path: 'detail/settings',
        component: SettingsComponent,
        data: {animation: 'Settings'}
    },
    {
        path: 'detail/heating',
        component: HeatingDetailComponent,
        data: {animation: 'HeatingrDetail'}
    },
    {
        path: 'detail/car',
        component: CarDetailComponent,
        data: {animation: 'CarDetail'}
    },
    {
        path: 'detail/diagram/:for',
        component: LiveDiagramComponent,
        data: {animation: 'Diagram'}
    },

    // {
    //     path: 'detail/car',
    //     component: CarDetailComponent,
    //     data: {animation: 'CarDetail'}
    // },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
