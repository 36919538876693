import {Injectable} from '@angular/core';
import {of} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SettingsService {
    options: Array<object> = [
        {
            title: 'Photovoltaik',
            iconPath() {
                return '/assets/svg/settings/' + this.title + '.svg';
            },
            details: [
                {
                    title: 'Anzahl der Module',
                    value: '230'
                },
                {
                    title: 'Leistung',
                    value: '9,6 kWp'
                },
                {
                    title: 'Wechselrichter',
                    value: 'PIKO IQ 8.5 Kostal'
                }
            ]
        },
        {
            title: 'Batteriespeicher',
            iconPath() {
                return '/assets/svg/settings/' + this.title + '.svg';
            },
            details: [
                {
                    title: 'Typ',
                    value: 'VARTA pulse 6'
                },
                {
                    title: 'Kapazität',
                    value: '5.85 kWh (nutzbar)'
                },
                {
                    title: 'Gewicht',
                    value: '65 kg'
                }
            ]
        },
        {
            title: 'Wärmespeicher',
            iconPath() {
                return '/assets/svg/settings/' + this.title + '.svg';
            },
            details: [
                {
                    title: 'Typ',
                    value: 'Stiebel Eltron ETW Plus'
                },
                {
                    title: 'Anschlussleistung',
                    value: '1200 - 2500 W'
                },
                {
                    title: 'Netzanschluss',
                    value: '3/N/PE~400 V '
                }
            ]
        },
        {
            title: 'E-Auto',
            iconPath() {
                return '/assets/svg/settings/' + this.title + '.svg';
            },
            details: [
                {
                    title: 'Typ',
                    value: 'BMW i3'
                },
                {
                    title: 'Stromverbrauch',
                    value: '13,1 kWh/100 km'
                },
                {
                    title: 'Batterietyp',
                    value: 'Li-Ion 37,9 kWh'
                }
            ]
        },
    ];

    constructor() {
    }

    get() {
        return of(this.options);
    }

}
