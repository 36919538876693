/**
 * Calculate Mean value
 * @param array
 */
export const mean = (array: Array<number>) => {
    let sum = 0;
    array.forEach((el) => sum += el);
    return sum / array.length;
};

/**
 * Calculate the variance
 * @param array
 */
export const variance = (array: Array<number>) => {
    const m = mean(array);
    return mean(array.map((num) => Math.pow(num - m, 2)));
};

/**
 * Calculate Standard Deviation
 * @param array
 */
export const stdDev = (array: Array<number>) => {
    return Math.sqrt(variance(array));
};

/**
 * Calculate the median
 * @param array
 */
export const median = (array: Array<number>) => {
    array.sort((a, b) => a - b);
    const mid = array.length / 2;
    return mid % 1 ? array[mid - 0.5] : (array[mid - 1] + array[mid]) / 2;
};


export const sum = (array: Array<number>) => {
    let sum = 0;
    for(const val of array) {
        sum += val;
    }
    return sum;
}
