import {SubmenuOptions} from '../submenu/submenu.component';

export const submenuConfig: SubmenuOptions = {
    items: [
        {
            title: 'Übersicht',
            route: '/dashboard'
        },
        {
            title: 'Einstellungen',
            route: '/detail/settings'
        },
        {
            title: 'Wärmespeicher',
            route: '/detail/heating'
        },
        {
            title: 'E-Auto',
            route: '/detail/car'
        }
    ]
};
