export interface DiagramConfig {
    xAxis: {
        show: boolean;
        position: 'top' | 'bottom';
        title: string,
    };
    yAxis: {
        plotLines: {
            show: boolean;
        },
        min: number,
        title: string,
    };
}

/**
 * CONFIGS
 */
export const upperDiagramConfig: DiagramConfig = {
    xAxis: {
        title: '',
        position: 'top',
        show: false,
    },
    yAxis: {
        title: 'Netzauslastung MW',
        plotLines: {
            show: false
        },
        min: null
    }
};

export const lowerDiagramConfig: DiagramConfig = {
    xAxis: {
        title: 'Tageszeit',
        position: 'bottom',
        show: true,
    },
    yAxis: {
        title: 'CO2-Anteil g/kWh',
        plotLines: {
            show: true
        },
        min: 450
    }
};

export const diagramColors = {
    grid: {
        grey: '#d8d8d8'
    },
    label: {
        grey: '#3c3732fd',
    },
    grey: '#c4c3c1',
    green: '#64b42d',
    yellow: '#ffc300',
    red: '#eb4b0a',
    blue: '#009ba5',
};
