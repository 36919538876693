import {Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {SettingsService} from '../services/settings.service';
import {Router} from '@angular/router';
import {Subscription, timer} from 'rxjs';

@Component({
    selector: 'app-car-detail',
    templateUrl: './car-detail.component.html',
    styleUrls: ['./car-detail.component.scss']
})
export class CarDetailComponent implements OnInit {

    public timespanTitles: Array<string> = ['Abfahrtzeit wählen'];
    public options: object;

    private containerVisible = false;
    private hideTimerSub: Subscription = null;
    private hideAnimationSub: Subscription = null;

    @ViewChild('root', {static: true}) private rootContainer: ElementRef;

    constructor(private settingsService: SettingsService,
                private router: Router,
                private renderer: Renderer2) {
    }

    ngOnInit() {
        this.settingsService.get().subscribe((res: any) => {
            res.forEach((inner) => {
                this.options = (inner.title === 'E-Auto') ? inner : '';
            });
        });

        this.renderer.setStyle(this.rootContainer.nativeElement, 'position', 'relative');
    }

    routeToDiagram(): void {
        this.router.navigate(['detail', 'diagram', 'car']);
    }

    onSave(): void {
        this.showPopover('Die eingegebenen Daten wurden gespeichert.');
    }

    /**
     * Show Popover
     * @param text
     */
    private showPopover(text: string): void {
        if (this.containerVisible) {
            if (this.hideAnimationSub) {
                this.hideAnimationSub.unsubscribe();
            }
            if (this.hideTimerSub) {
                this.hideTimerSub.unsubscribe();
            }
            return;
        }

        const tooltip_div = this.createTooltipDiv(text);
        this.renderer.appendChild(this.rootContainer.nativeElement, tooltip_div);
        this.renderer.addClass(tooltip_div, 'tooltip-visible');
        this.containerVisible = true;

        this.hideTimerSub = timer(2000).subscribe(
            () => {
                const inserted = this.rootContainer.nativeElement.querySelector('.tooltip');
                this.renderer.addClass(inserted, 'tooltip-hidden');
                this.hideAnimationSub = timer(200).subscribe(() => {
                    this.renderer.removeChild(this.rootContainer.nativeElement, inserted);
                    this.containerVisible = false;
                });
            }
        );

    }

    /**
     * Create new tooltip div
     * @param content string
     */
    private createTooltipDiv(content: string): HTMLElement {
        const d = document.createElement('div') as HTMLDivElement;
        this.renderer.addClass(d, 'tooltip');
        this.renderer.addClass(d, 'tooltip-custom');
        this.renderer.addClass(d, 'tooltip-save');

        d.innerText = content;
        return d;
    }
}
