import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {interval, Observable, Subscription} from 'rxjs';
import {mean, stdDev, sum} from '../lib/Statistics';
import * as moment from 'moment';
import {diagramColors, lowerDiagramConfig, upperDiagramConfig} from '../config/diagram.config';
import {diagramDropdownConfig} from '../config/dropdown.config';
import {Location} from '@angular/common';
import {ApiService, Incentive, IncentiveItem} from '../api.service';

@Component({
    selector: 'app-live-diagram',
    templateUrl: './live-diagram.component.html',
    styleUrls: ['./live-diagram.component.scss']
})
export class LiveDiagramComponent implements OnInit, OnDestroy {

    lowerDiagramData: Array<Array<number>> = null;
    upperDiagramData: Array<Array<number>> = null;

    lowerConfig = lowerDiagramConfig;
    upperConfig = upperDiagramConfig;

    dropdownConfig = diagramDropdownConfig;

    batteryCharge = 12;

    batteryAnimationConfig = {
        path: 'assets/anim/battery.json',
        width: '102px',
        height: '56px',
    };

    mode: 'heating' | 'car' = null;

    timeframe: 'today' | 'yesterday' | 'tomorrow' = 'today';

    carConfig = {
        startTime: null,
        endTime: null,
        hoursToCharge: 3
    };

    heatingConfig = {
        firstInterval: {
            startTime: null,
            endTime: null,
            hoursToCharge: 1
        },
        secondInterval: {
            startTime: null,
            endTime: null,
            hoursToCharge: 3
        }
    };

    private updateInterval$: Observable<number> = null;
    private updateSubscription: Subscription = null;
    private colors = diagramColors;


    constructor(private route: ActivatedRoute,
                private api: ApiService,
                public location: Location) {
    }

    ngOnInit() {
        this.route.params.subscribe((params: Params) => {
            this.mode = params.for;
            if (!this.mode) {
                console.log('Error: no mode specified!');
            }
            this.initialize();
        });

    }

    ngOnDestroy(): void {
        if (this.updateSubscription) {
            this.updateSubscription.unsubscribe();
        }
    }

    /**
     * On dropdown time change callback.
     * @param change change event
     */
    onTimeChange(change): void {
        this.api.getCo2Incentives().subscribe(
            (values: Incentive) => {

                this.timeframe = change.tag;

                // align start and end date
                if (change.tag === 'yesterday') {
                    this.carConfig.startTime = moment().subtract(2, 'day')
                        .hour(22).minute(0).second(0).millisecond(0).toDate();
                    this.carConfig.endTime = moment().subtract(1, 'day')
                        .hour(8).minute(0).second(0).millisecond(0).toDate();

                    this.heatingConfig.firstInterval.startTime = moment().subtract(2, 'day')
                        .hour(22).minute(0).second(0).millisecond(0).toDate();
                    this.heatingConfig.firstInterval.endTime = moment().subtract(1, 'day')
                        .hour(8).minute(0).second(0).millisecond(0).toDate();

                    this.heatingConfig.secondInterval.startTime = moment().subtract(1, 'day')
                        .hour(12).minute(0).second(0).millisecond(0).toDate();
                    this.heatingConfig.secondInterval.endTime = moment().subtract(1, 'day')
                        .hour(17).minute(0).second(0).millisecond(0).toDate();


                } else if (change.tag === 'today') {
                    this.carConfig.startTime = moment().subtract(1, 'day')
                        .hour(22).minute(0).second(0).millisecond(0).toDate();
                    this.carConfig.endTime = moment().hour(8).minute(0).second(0).millisecond(0).toDate();

                    this.heatingConfig.firstInterval.startTime = moment().subtract(1, 'day')
                        .hour(22).minute(0).second(0).millisecond(0).toDate();
                    this.heatingConfig.firstInterval.endTime = moment().hour(8).minute(0).second(0).millisecond(0).toDate();

                    this.heatingConfig.secondInterval.startTime = moment().hour(12).minute(0).second(0).millisecond(0).toDate();
                    this.heatingConfig.secondInterval.endTime = moment().hour(17).minute(0).second(0).millisecond(0).toDate();
                }

                this.generateDiagramData(values, change);
            }
        );
    }


    /**
     * Initialize
     */
    private initialize(): void {
        switch (this.mode) {
            case 'car':
                this.carConfig.startTime = moment().hour(22).minute(0).second(0).millisecond(0)
                    .subtract(1, 'day').toDate();
                this.carConfig.endTime = moment().hour(8).minute(0).second(0).millisecond(0).toDate();
                break;
            case 'heating':
                this.heatingConfig.firstInterval.startTime =
                    moment().hour(22).minute(0).second(0).millisecond(0)
                        .subtract(1, 'day').toDate();
                this.heatingConfig.firstInterval.endTime =
                    moment().hour(8).minute(0).second(0).millisecond(0).toDate();

                this.heatingConfig.secondInterval.startTime =
                    moment().hour(12).minute(0).second(0).millisecond(0).toDate();
                this.heatingConfig.secondInterval.endTime =
                    moment().hour(17).minute(0).second(0).millisecond(0).toDate();
                break;
            default:
                console.log('Error: unknown or no mode specified');
                break;
        }

        this.requestIncentiveData();
        this.updateInterval$ = interval(15 * 60 * 1000);
        this.updateSubscription = this.updateInterval$.subscribe(() => {
            this.requestIncentiveData();
        });
    }


    /**
     * Meta function to generate the diagram data.
     * @param values
     * @param change
     */
    private generateDiagramData(values: Incentive, change): void {
        let mapped_data = null;
        const diagram_data_points = this.generateDiagramDataPoints(values);
        const negative_data_points = this.generateDiagramDataPoints(this.calculateDataNegative(values));

        if (this.mode === 'car') {
            mapped_data = this.mapData(diagram_data_points,
                this.carConfig.startTime,
                this.carConfig.endTime,
                this.carConfig.hoursToCharge, true);
        } else if (this.mode === 'heating') {
            const first_interval_mapped_data = this.mapData(diagram_data_points,
                this.heatingConfig.firstInterval.startTime,
                this.heatingConfig.firstInterval.endTime,
                this.heatingConfig.firstInterval.hoursToCharge,
            );
            mapped_data = this.mapData(first_interval_mapped_data,
                this.heatingConfig.secondInterval.startTime,
                this.heatingConfig.secondInterval.endTime,
                this.heatingConfig.secondInterval.hoursToCharge,
            );
        } else {
            console.log('Error: no valid mode specified');
        }

        if (change) {
            this.lowerDiagramData = this.filterDataByDate(mapped_data, change.tag);
            this.upperDiagramData = this.filterDataByDate(negative_data_points, change.tag);
        } else {
            this.lowerDiagramData = this.filterDataByDate(mapped_data);
            this.upperDiagramData = this.filterDataByDate(negative_data_points);
        }
    }

    /**
     * Generic function to generate and map the final highlighted data
     * @param diagram_data_points
     * @param start_time
     * @param end_time
     * @param num_hours
     */
    private mapData(diagram_data_points, start_time, end_time, num_hours, show_logs = false): Array<DiagramDataPoint> {
        // extract elements in given timeframe
        let elements = [];
        diagram_data_points.forEach((el: DiagramDataPoint, index: number) => {
            if (el.x > start_time && el.x < end_time) {
                elements.push({x: el.x, y: el.y, idx: index});
            }
        });

        // find quadruples of green
        elements = elements.reverse();
        const filtered_quads = this.filterQuads(elements);

        // create variant map
        const variant_map = this.createVariantMap(filtered_quads);

        // calculate the mean of each variants first 3 elements --> find the most efficient variant
        const summed = [];
        variant_map.forEach((value, idx) => {
            const first_3 = value.slice(0, num_hours).map((val) => val.mean);
            summed.push({mean: mean(first_3), idx: idx});
        });

        // sort by smallest summed mean value
        summed.sort(this.sortByMean);

        // find the smallest elements in the first sorted index
        const smallest = variant_map[summed[0].idx];

        // sort the found quads by smallest mean
        const found_segment = filtered_quads[smallest[0].outterIdx];

        const final_means = [];
        for (let i = 0; i < found_segment.length; ++i) {
            final_means.push({
                mean: mean(found_segment[i].map((val) => val.y)),
                idx: i,
                found_segment: found_segment[i]
            });
        }

        if (show_logs) {
            console.log('final-means', final_means);
        }

        const final_hour_means = final_means.sort(this.sortByMean).slice(0, num_hours);

        if (show_logs) {
            console.log(final_hour_means);
        }

        let final = [];
        for (const hour of final_hour_means) {
            final = final.concat(hour.found_segment);
        }

        return this.determineDiagramPointColors(diagram_data_points, final);

    }

    /**
     * Filter from yesterday to tomorrow by day. 'today' is default.
     * @param data data
     * @param date filter date string
     */
    private filterDataByDate(data: Array<DiagramDataPoint>,
                             date: 'yesterday' | 'today' | 'tomorrow' = 'today'): any {

        let from = null;
        let to = null;
        const now = moment();
        switch (date) {
            case 'today':
                from = moment(now).hour(0).minute(0).second(0).millisecond(0).unix();
                to = moment(now).hour(24).minute(0).second(0).millisecond(0).unix();
                break;
            case 'tomorrow':
                from = moment(now).add(1, 'day').hour(0).minute(0).second(0).millisecond(0).unix();
                to = moment(now).add(1, 'day').hour(24).minute(0).second(0).millisecond(0).unix();
                break;
            case 'yesterday':
                from = moment(now).subtract(1, 'day').hour(0).minute(0).second(0).millisecond(0).unix();
                to = moment(now).subtract(1, 'day').hour(24).minute(0).second(0).millisecond(0).unix();
                break;
        }

        // return data.filter((value: DiagramDataPoint) => value.x / 1000 >= from && value.x / 1000 < to);

        const filtered = [];
        for (const element of data) {
            if (element.x / 1000 >= from) {
                if (element.x / 1000 < to) {
                    filtered.push(element);
                }
            }
        }
        return filtered;
    }

    /**
     * Request Incentive data
     */
    private requestIncentiveData(): void {
        this.api.getCo2Incentives().subscribe(
            (values: Incentive) => {
                this.generateDiagramData(values, null);
            }
        );
    }

    /**
     * Calculcate the upper diagram data, based on live API values.
     * @param data dataset
     */
    private calculateDataNegative(data: Incentive): Incentive {
        const items = data.items;
        const max = Math.max.apply(Math, items.map((obj: IncentiveItem) => obj.value));
        const values = items.map((el: IncentiveItem) => el.value);

        // const avg = mean(values);
        const stddev = stdDev(values);

        items.forEach((el: IncentiveItem) => {
            const negative = max - el.value;
            const base = 10;
            const new_value = (negative * (el.value + negative * stddev) + stddev);
            el.value = base + new_value / 100000;
        });

        return data;
    }

    /**
     * Generates list of DiagramDataPoint interfaces for rendering the diagram.
     * @param data Array<Incentive>
     */
    private generateDiagramDataPoints(data: Incentive): Array<DiagramDataPoint> {
        const mapped: Array<DiagramDataPoint> = [];
        for (const el of data.items) {
            const t = new Date(el.startTime);
            mapped.push(
                {
                    x: t.getTime(),
                    y: el.value,
                    color: this.colors.grey
                }
            );
        }
        return mapped;
    }

    /**
     * Callback sort function.
     * @param a
     * @param b
     */
    private sortByMean(a: { mean: number, idx: number }, b: { mean: number, idx: number }): number {
        if (a.mean > b.mean) {
            return 1;
        }
        if (a.mean < b.mean) {
            return -1;
        }
        return 0;
    }

    /**
     * Create 4 different maps for all combinations of 4 values.
     * @param time_filtered_elements
     */
    private filterQuads(time_filtered_elements): any {
        let map = [];
        for (let o = 0; o < 4; ++o) {
            let quads = [];
            for (let i = o; i < time_filtered_elements.length; ++i) {
                const segment = time_filtered_elements.slice(i, i + 4);
                if (segment.length === 4) {
                    quads.push(segment);
                }
                i = i + 3;
            }
            map.push(quads);
        }
        return map;
    }

    private createVariantMap(filtered_quads): any {
        const variant_map = [];
        for (let o = 0; o < filtered_quads.length; ++o) {
            const element = filtered_quads[o];
            const inner = [];
            for (let i = 0; i < filtered_quads.length; ++i) {
                const obj = {
                    mean: mean(element[i].map(el => el.y)),
                    outterIdx: o,
                    innerIdx: i,
                };
                inner.push(obj);
            }
            variant_map.push(inner);
        }
        return variant_map;
    }


    private determineDiagramPointColors(original_points: Array<DiagramDataPoint>, to_mark: Array<any>): Array<DiagramDataPoint> {
        for (const datapoint of to_mark) {
            if (datapoint.y < 270) {
                original_points[datapoint.idx].color = this.colors.green;
            } else if (datapoint.y >= 270 && datapoint.y < 450) {
                original_points[datapoint.idx].color = this.colors.yellow;
            } else if (datapoint.y >= 450) {
                original_points[datapoint.idx].color = this.colors.red;
            }
        }
        return original_points;

    }

}

export interface DiagramDataPoint {
    x: number;
    y: number;
    color: string;
}

// /**
//  * Map data
//  * @param data data
//  * @param ignore_color ignore color flag
//  */
// private mapCarData(data: Incentive, ignore_color = false): Array<DiagramDataPoint> {
//     const diagram_data_points = this.generateDiagramDataPoints(data);
//     if (ignore_color) {
//         return diagram_data_points;
//     }
//
//     // extract elements in given timeframe
//     let elements = [];
//     diagram_data_points.forEach((el: DiagramDataPoint, index: number) => {
//         if (el.x > this.carConfig.startTime && el.x < this.carConfig.endTime) {
//             elements.push({x: el.x, y: el.y, idx: index});
//         }
//     });
//
//     // find quadruples of green
//     elements = elements.reverse();
//     const filtered_quads = this.filterQuads(diagram_data_points, elements);
//
//     // create variant map
//     const variant_map = this.createVariantMap(filtered_quads);
//
//     // calculate the mean of each variants first 3 elements --> find the most efficient variant
//     const summed = [];
//     variant_map.forEach((value, idx) => {
//         const first_3 = value.slice(0, 3).map((val) => val.mean);
//         summed.push({mean: mean(first_3), idx: idx});
//     });
//
//     // sort by smallest summed mean value
//     summed.sort(this.sortBySum);
//
//     // find the smallest elements in the first sorted index
//     const smallest = variant_map[summed[0].idx];
//
//     // sort the found quads by smallest mean
//     const found_segment = filtered_quads[smallest[0].outterIdx];
//
//     const final_means = [];
//     for (let i = 0; i < found_segment.length; ++i) {
//         final_means.push(
//             {
//                 mean: mean(found_segment[i].map((val) => val.y)),
//                 idx: i,
//                 found_segment: found_segment[i]
//             }
//         );
//     }
//     const final_hour_means = final_means.sort(this.sortBySum).slice(0, 4);
//
//     let final = [];
//     for (const hour of final_hour_means) {
//         final = final.concat(hour.found_segment);
//     }
//
//     // mark the respective elements
//     // console.log(final);
//     // final -> [x, y, idx]
//     return this.determineDiagramPointColors(diagram_data_points, final);
//
// }
