import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'information-tile',
  templateUrl: './information-tile.component.html',
  styleUrls: ['./information-tile.component.scss']
})
export class InformationTileComponent implements OnInit {
  @Input('showTitle') showTitle: boolean = true;
  @Input('item') item: object;

  constructor() { }

  ngOnInit() {
  }

}
