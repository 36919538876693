import { Component, OnInit, Input, Output, EventEmitter, HostBinding } from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import { pulseAnimation } from '../animations/reusable.animation';

@Component({
  selector: 'time-spinner',
  templateUrl: './time-spinner.component.html',
  styleUrls: ['./time-spinner.component.scss'],
  animations: [
    trigger('pulseUp', [
      transition(
        ':increment',
        useAnimation(pulseAnimation, {
          params: {
            timings: '400ms cubic-bezier(.11,.99,.83,.43)',
            scale: 1.1
          }
        })
      ),
    ]),
    trigger('pulseDown', [
      transition(
        ':decrement',
        useAnimation(pulseAnimation, {
          params: {
            timings: '400ms cubic-bezier(.11,.99,.83,.43)',
            scale: 1.05
          }
        })
      ),
    ])
  ]
})

export class TimeSpinnerComponent implements OnInit {
  @HostBinding('@pulseUp')
  @HostBinding('@pulseDown')

  @Input('startTime') startTime: number = 8;
  @Input('endTime') endTime: number = 22;
  @Input('step') step: number = 15;

  @Output('times') _times = new EventEmitter<{ hours: number, minutes: number }>();

  private minutes: number;
  private hours: number;

  constructor() { }

  ngOnInit() {
    this.hours = this.startTime;
    this.minutes = 0;
  }

  increaseHours() {
    if (this.hours < this.endTime) {
      this.hours++;
      this.emitActualTime();
    };
  }

  decreaseHours() {
    if (this.hours > this.startTime) {
      this.hours--;
      this.emitActualTime();
    }
  }

  increaseMinutes() {
    if ((this.minutes + this.step) < 60 ) {
      this.minutes = this.minutes + this.step;
      this.emitActualTime();
    } 
  }

  decreaseMinutes() {
    if ((this.minutes - this.step) >= 0) {
      this.minutes = this.minutes - this.step;
      this.emitActualTime();
    }
  }

  emitActualTime() {
    this._times.emit({ hours: this.hours, minutes: this.minutes });
  }

}
