import {Injectable} from '@angular/core';
import { Observable , throwError} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {catchError, flatMap, map} from 'rxjs/operators';
import * as moment from 'moment';
import {environment} from '../environments/environment';
import {Url} from './lib/Url';

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    private routes = {
        incentive: {
            all: 'incentive',

        }
    };

    private incentives: Incentive[] = null;
    incentivesLoaded = false;

    private cachedIncentives: Incentive[] = null;

    private cachedCo2Incentives: Incentive = null;
    private cachedPriceIncentives: Incentive = null;

    constructor(private http: HttpClient) {
        this.requestAllIncentives().subscribe(
            (response) => {
                if (response) {
                    this.incentives = response;
                    this.incentivesLoaded = true;
                }
            },
            (error) => {
                console.log('Error', error);

            }
        );

    }

    getAllIncentives(): Observable<any> {
        if (this.cachedIncentives === null) {
            return this.requestAllIncentives();
        }
        return new Observable(
            (observer) => {
                observer.next(this.cachedIncentives);
                observer.complete();
            }
        );

    }

    getCo2Incentives(): Observable<any> {
        return this.getAllIncentives().pipe(
            flatMap((incentiveResponse: Incentive[], idx) => {
                const inc = incentiveResponse.find((element: Incentive) => element.type.toLowerCase() === 'other');
                return this.getCo2IncentivesForDay().pipe(
                    map((res: Incentive) => {
                        // res.items.forEach((element: IncentiveItem) => {
                        //     element.startTime = new Date(element.startTime);
                        // });
                        this.cachedCo2Incentives = res;
                        return res;
                    })
                );
            })
        );
    }

    getCo2IncentivesForDay(): Observable<any> {
        const now = moment();
        const from = moment(now).subtract(1, 'day').hour(0).minute(0).second(0).millisecond(0).unix();
        const to = moment(now).add(1, 'day').hour(24).minute(0).second(0).millisecond(0).unix();
        return this.getAllIncentives().pipe(
            flatMap((incentiveResponse: Incentive[], idx) => {
                const inc = incentiveResponse.find((element: Incentive) => element.type.toLowerCase() === 'other');
                return this.getIncentivesForTimeframeById(inc.id, from, to).pipe(
                    map((res: Incentive) => {
                        // res.items.forEach((element: IncentiveItem) => {
                        //     element.startTime = new Date(element.startTime);
                        // });
                        // this.cachedCo2Incentives = res;
                        return res;
                    })
                );
            })
        );
    }

    private requestAllIncentives(): Observable<Incentive[] | any> {
        const url = new Url('ssl', environment.baseUrl);
        url.push(this.routes.incentive.all);

        return this.http.get(url.toString()).pipe(
            map((res) => {
                if (res !== null && res !== undefined) {
                    this.incentivesLoaded = true;
                }
                return res as Incentive[];
            }),
            catchError((error) => {
                console.log(error);
                return throwError(error);
            })
        );
    }

    private getIncentivesForTimeframeById(id: string, from: number, to: number): Observable<Incentive | any> {
        const url = new Url('ssl', environment.baseUrl);
        url.push(this.routes.incentive.all);
        url.push(id);
        url.push(from.toString());
        url.push(to.toString());

        return this.http.get(url.toString()).pipe(
            map((res: Incentive) => {
                // if ('items' in res) {
                //     res.items.forEach((element) => {
                //         element.startTime = new Date(element.startTime);
                //     });
                // }
                return res;
            }),
            catchError((error) => {
                console.log(error);
                return throwError(error);
            })
        );
    }
}


export interface Incentive {
    id: string;
    name: string;
    type: 'price' | 'other';
    duration: number;
    unit: string;
    items: IncentiveItem[] | null;
}

export interface IncentiveItem {
    startTime: Date;
    value: number;
    score: number;
    zone: number;
}

