import {Component, OnInit, ElementRef, ViewChild, Renderer2, AfterViewInit, Input, Output, EventEmitter} from '@angular/core';

@Component({
    selector: 'temperature-picker',
    templateUrl: './temperature-picker.component.html',
    styleUrls: ['./temperature-picker.component.scss']
})
export class TemperaturePickerComponent implements OnInit, AfterViewInit {
    private globalListenerFunc: Function;
    public degrees: { value: number, formatted: string };
    public lastEmittedTemperature: number;

    @ViewChild('selectormanuell', {static: false}) private selector: ElementRef;

    @Input('minAngle') minAngle: number = 22;
    @Input('maxAngle') maxAngle: number = 340;
    @Input('startTemp') startTemperature: number = 5;
    @Input('endTemp') endTemperature: number = 30;
    @Input('showSave') showSaveButton: boolean = false;

    @Output('temperature') _selectedTemperature = new EventEmitter<number>();
    @Output('onSave') _onSave = new EventEmitter();

    constructor(private renderer: Renderer2) {
    }

    ngOnInit() {
        this.degrees = {
            value: this.startTemperature,
            formatted: this.startTemperature + ',0°C'
        };
    }

    ngAfterViewInit() {
        this.makeSelectorRotatable(this.minAngle, this.maxAngle, this.startTemperature, this.endTemperature);
    }

    getDegrees(mouse_x: number, mouse_y: number, center_x: number, center_y: number) {
        const radians = Math.atan2(mouse_x - center_x, mouse_y - center_y);
        const degrees = Math.round((radians * (180 / Math.PI) * -1) + 180);

        return degrees;
    }

    makeSelectorRotatable(minAngle: number, maxAngle: number, startTemperature: number, endTemperature: number) {
        const selector = this.selector.nativeElement as HTMLDivElement;
        const radius = selector.offsetWidth / 2;
        const center_x = selector.offsetLeft + radius;
        const center_y = selector.offsetTop + radius;

        selector.style.transform = 'rotate(' + minAngle + 'deg)';

        selector.onmousedown = (ev) => {

            if (this.globalListenerFunc) {
                this.globalListenerFunc();
            }

            this.globalListenerFunc = this.renderer.listen('window', 'mousemove', e => {
                const degrees = this.getDegrees(e.pageX, e.pageY, center_x, center_y);

                if (degrees >= minAngle && degrees < maxAngle) {

                    selector.style.transform = 'rotate(' + degrees + 'deg)';
                    this.translateDegressToTemperature(degrees, startTemperature, endTemperature, minAngle, maxAngle);

                }
            });

            let cancelEvent = this.renderer.listen('window', 'mouseup', e => {
                this.globalListenerFunc();
                cancelEvent();
            });
        };
    }

    translateDegressToTemperature(
        actualDegree: number,
        startTemperature: number,
        endTemperature: number,
        minAngle: number,
        maxAngle: number) {
        const temperatureDelta: number = endTemperature - startTemperature;
        const angleDelta: number = maxAngle - minAngle;
        const stepDegree: number = (angleDelta / (temperatureDelta + 1));
        const selectedStep = Math.floor((actualDegree - minAngle) / stepDegree);

        this.degrees = {value: startTemperature + selectedStep, formatted: startTemperature + selectedStep + ',0°C'};

        if (this.lastEmittedTemperature !== this.degrees.value) {
            this._selectedTemperature.emit(this.degrees.value);
        }
        this.lastEmittedTemperature = this.degrees.value;
    }

}
