import {Component, OnInit} from '@angular/core';
import {SettingsService} from '../services/settings.service';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

    public options: Array<object>;

    constructor(private settingsService: SettingsService) {
    }

    ngOnInit() {
        this.settingsService.get().subscribe((res) => {
            this.options = res;
        });
    }

}
