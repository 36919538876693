import {animate, query, state, style, transition, trigger} from '@angular/animations';

export const dashboardFade =
    trigger('dashboardFade', [
        state('hidden', style({opacity: 0})),
        state('visible', style({opacity: 1})),
        transition('hidden <=> visible', [
            animate('1000ms ease', style({
                    opacity: 1
                }
            ))
        ]),
    ]);
