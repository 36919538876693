import {Component, OnInit, Renderer2} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {routerFade} from '../animations/page-fade.animation';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-detail',
    templateUrl: './detail.component.html',
    styleUrls: ['./detail.component.scss'],
    animations: [routerFade]
})
export class DetailComponent implements OnInit {

    showLiveChart = false;


    constructor(private renderer: Renderer2) {
    }

    ngOnInit() {
    }

    prepareRoute(outlet: RouterOutlet) {
        return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
    }

}


