import {Component, Input, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

@Component({
    selector: 'app-submenu',
    templateUrl: './submenu.component.html',
    styleUrls: ['./submenu.component.scss']
})
export class SubmenuComponent implements OnInit {

    @Input('options') options: SubmenuOptions;

    activeOption: string = '/dashboard';
    showMenu: boolean = true;

    constructor(private router: Router) {
    }

    ngOnInit() {
        this.initialize();
    }

    private initialize(): void {
        this.router.events.subscribe((events) => {
            if (events instanceof NavigationEnd) {
                this.showMenu = !(events.urlAfterRedirects.includes('tutorial') ||
                    events.url.includes('tutorial'));

                this.activeOption = events.url;

                if (events.url.includes('diagram')) {
                    if (events.url.includes('car')) {
                        for (const element of this.options.items) {
                            if (element.route.includes('car')) {
                                this.activeOption = element.route;
                            } else if (element.route.includes('heating')) {
                                this.activeOption = element.route;
                            }
                        }
                    }
                }

            }
        });
    }
}

export interface SubmenuItem {
    title: string;
    route: string;
}

export interface SubmenuOptions {
    items: SubmenuItem[];
}

