import {Directive, ElementRef, Input, OnInit, Renderer2} from '@angular/core';
import {Subscription, timer} from 'rxjs';
import * as moment from 'moment';

@Directive({
    selector: '[tooltip]'
})
export class TooltipDirective implements OnInit {

    @Input('tooltip') options;
    @Input('timeout') timeout = 300;
    @Input('classname') className: string;

    private showTimerSub: Subscription = null;
    private hideTimerSub: Subscription = null;
    private hideAnimationSub: Subscription = null;

    private mouseInTime = null;

    private containerVisible = false;

    constructor(private el: ElementRef,
                private renderer: Renderer2) {
    }

    ngOnInit(): void {
        this.setupMouseEnterEvent();
        this.setupMouseLeaveEvent();
    }

    /**
     * Setup the MouseEnter event.
     */
    private setupMouseEnterEvent(): void {
        this.renderer.setStyle(this.el.nativeElement, 'position', 'relative');
        this.renderer.listen(this.el.nativeElement, 'mouseenter', (event) => {
            this.mouseInTime = new Date();
            if (this.containerVisible) {
                this.hideAnimationSub.unsubscribe();
                this.hideTimerSub.unsubscribe();
                return;
            }
            this.showTimerSub = timer(this.timeout).subscribe(() => {
                    const tooltip_div = this.createTooltipDiv(this.options.text);
                    this.renderer.appendChild(this.el.nativeElement, tooltip_div);
                    this.renderer.addClass(tooltip_div, 'tooltip-visible');
                    if (this.className) {
                        this.renderer.addClass(tooltip_div, this.className);
                    }
                    this.containerVisible = true;
                }
            );
        });

    }

    /**
     * Setup the MouseLeave event.
     */
    private setupMouseLeaveEvent(): void {
        this.renderer.listen(this.el.nativeElement, 'mouseleave', (event) => {
            const diff = moment().diff(moment(this.mouseInTime), 'ms');

            if (diff < this.timeout) {
                this.showTimerSub.unsubscribe();
                return;
            }

            this.hideTimerSub = timer(this.timeout - 200).subscribe(() => {
                const inserted = this.el.nativeElement.querySelector('.tooltip');
                this.renderer.addClass(inserted, 'tooltip-hidden');
                this.hideAnimationSub = timer(200).subscribe(() => {
                    this.renderer.removeChild(this.el.nativeElement, inserted);
                    this.containerVisible = false;
                });

            });
        });
    }

    /**
     * Create new tooltip div
     * @param content string
     */
    private createTooltipDiv(content: string): HTMLElement {
        const d = document.createElement('div') as HTMLDivElement;
        this.renderer.addClass(d, 'tooltip');
        this.renderer.addClass(d, 'tooltip-custom');

        d.innerText = content;
        return d;
    }

}

