import {Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {AnimationOptions} from 'ngx-lottie';
import {dashboardFade} from '../animations/dashboard.animation';
import {interval, Observable, Subscription, timer} from 'rxjs';
import {Router} from '@angular/router';
import {ApiService, Incentive, IncentiveItem} from '../api.service';
import * as moment from 'moment';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    animations: [dashboardFade]
})
export class DashboardComponent implements OnInit, OnDestroy {

    @ViewChild('clickies', {static: true}) clickies: ElementRef;

    lottieConfig: AnimationOptions = {
        path: 'assets/anim/dash.json'
    };

    private houseSvg: SVGElement = null;
    private treeSvg: SVGElement = null;
    private cloudSvg: SVGElement = null;

    currentCo2Item: IncentiveItem = null;
    incentiveState: 'bad' | 'medium' | 'good' = 'good';

    treeState: 'bad' | 'medium' | 'good' | string = 'good';
    cloudState: 'bad' | 'medium' | 'good' | string = 'good';

    updateInterval = new Observable();
    updateSubscription: Subscription = null;

    treeTooltipConfig = {text: ''};
    treeTooltips = {
        good: 'Herzlichen Glückwunsch! Du bist grün unterwegs! ...noch 7 Monate bis zur Bonus-Auszahlung.',
        medium: 'Oops...  Du bewegst dich momentan im gelben Bereich. Du hast noch 7 Monate, um Deine CO2-Bilanz zu verbessern und den Bonus zu erhöhen.',
        bad: 'Oh nein!  Momentan befindest Du Dich im roten Bereich. Du hast noch 7 Monate, um deine Präferenzen so zu ändern, dass sich Deine CO2-Bilanz verbessert und Du Deinen Bonus erhöhen kannst.'
    };
    cloudTooltips = {
        good: 'Dein Strom hat einen hohen Anteil an Erneuerbaren Energien',
        medium: 'Dein Strom hat einen durchschnittlichen Anteil an Erneuerbaren Energien',
        bad: 'Dein Strom hat einen sehr geringen Anteil an Erneuerbaren Energien'
    };

    // because I can't use my directive, thank you svg
    private timeout = 500;
    svgTooltips = {
        virtualCloud: {
            mouseInTime: null,
            hideAnimationSub: null,
            hideTimerSub: null,
            customClass: 'tooltip-virtual-cloud',
            text: 'Mit deinem virtuellen Speicher kannst Du bis zu 100 Prozent Deines erzeugten Stroms selber nutzen.',
            tooltipVisible: false,
        },
        co2: {
            mouseInTime: null,
            hideAnimationSub: null,
            hideTimerSub: null,
            customClass: 'tooltip-co2',
            text: '',
            tooltipVisible: false,
        }
    };


    constructor(private renderer: Renderer2,
                private api: ApiService,
                private router: Router) {
    }

    ngOnInit(): void {
        this.getCurrentValues();
        this.updateInterval = interval(15 * 60 * 1000);
        this.updateSubscription = this.updateInterval.subscribe(
            () => {
                this.getCurrentValues();
            }
        );

        // determine random tree state
        const state_array = Object.keys(this.treeTooltips);
        this.treeState = state_array[Math.floor(Math.random() * 3)];
    }

    ngOnDestroy(): void {
        if (this.updateSubscription) {
            this.updateSubscription.unsubscribe();
        }
    }

    /**
     * Callback on house SVG inserted.
     * @param element
     */
    onHouseSvgInserted(element: SVGElement): void {
        this.houseSvg = element;
        this.renderer.removeAttribute(element, 'title');
        this.renderer.setAttribute(this.houseSvg, 'width', '100%');
        this.renderer.setAttribute(this.houseSvg, 'height', 'auto');
    }

    /**
     * Callback on clickies SVG inserted.
     * @param element
     */
    onClickiesSvgInserted(element: SVGElement): void {
        this.renderer.setAttribute(element, 'width', '100%');
        this.renderer.setAttribute(element, 'height', 'auto');
        this.renderer.removeAttribute(element, 'title');

        // car
        const car = element.querySelector('#auto_click');
        this.setupClickableSVGAreas(car, 'detail/car');

        // heating
        const heating = element.querySelector('#waerme_click');
        this.setupClickableSVGAreas(heating, 'detail/heating');

        // virtual storage
        const virtual = element.querySelector('#virtual_click');
        this.renderer.listen(virtual, 'mouseenter', (evt) => this.mouseEnterEvent('virtualCloud'));
        this.renderer.listen(virtual, 'mouseleave', (evt) => this.mouseLeaveEvent('virtualCloud'));

        // cloud
        const cloud = element.querySelector('#cloud_click');
        this.renderer.listen(cloud, 'mouseenter', (evt) => this.mouseEnterEvent('co2', true));
        this.renderer.listen(cloud, 'mouseleave', (evt) => this.mouseLeaveEvent('co2'));
    }

    /**
     * Callback on tree SVG inserted.
     * @param element
     */
    onTreeSvgInserted(element: SVGElement): void {
        this.treeSvg = element;
        this.renderer.removeAttribute(element, 'title');
        this.renderer.setAttribute(this.treeSvg, 'width', '100%');
        this.renderer.setAttribute(this.treeSvg, 'height', '100%');
    }

    /**
     * Callback on cloud SVG inserted.
     * @param element
     */
    onCloudSvgInserted(element: SVGElement): void {
        this.cloudSvg = element;
        this.renderer.removeAttribute(element, 'title');
        this.renderer.setAttribute(this.cloudSvg, 'width', '100%');
        this.renderer.setAttribute(this.cloudSvg, 'height', 'auto');
    }

    /**
     * request the current values from the API.
     */
    getCurrentValues(): void {
        this.api.getCo2Incentives().subscribe(
            (values: Incentive) => {
                const found = values.items.findIndex((element, index) => {
                    const starttime = new Date(element.startTime);
                    if (starttime > new Date()) {
                        return true;
                    }
                });
                if (found > 0) {
                    this.currentCo2Item = values.items[found - 1];
                }

                let state = '';
                if (this.currentCo2Item.value <= 270) {
                    state = 'good';
                } else if (this.currentCo2Item.value > 270 && this.currentCo2Item.value < 450) {
                    state = 'medium';
                } else if (this.currentCo2Item.value >= 450) {
                    state = 'bad';
                }
                this.incentiveState = state as 'good' | 'medium' | 'bad';
                this.cloudState = state as 'good' | 'medium' | 'bad';

                this.treeTooltipConfig.text = this.treeTooltips[this.treeState];
                this.svgTooltips.co2.text = this.cloudTooltips[this.treeState];
            }
        );
    }

    /**
     * Setup interactive SVG areas.
     * @param el
     * @param route
     */
    private setupClickableSVGAreas(el: any, route: string): void {
        this.renderer.listen(el, 'click', () => {
            this.router.navigate(route.split('/'));
        });
        this.renderer.setAttribute(el, 'cursor', 'pointer');
    }

    /**
     * Create new tooltip div
     * @param content string
     */
    private createTooltipDiv(content: string): HTMLElement {
        const d = document.createElement('div') as HTMLDivElement;
        this.renderer.addClass(d, 'tooltip');
        this.renderer.addClass(d, 'tooltip-custom');
        d.innerHTML = content;
        return d;
    }

    /**
     * Callback for mouseEnter event.
     * @param config_key
     */
    private mouseEnterEvent(config_key: string, with_color = false): void {
        this.svgTooltips[config_key].mouseInTime = new Date();
        if (this.svgTooltips[config_key].containerVisible) {
            if (!this.svgTooltips[config_key].hideAnimationSub) {
                this.svgTooltips[config_key].hideAnimationSub.unsubscribe();
            }
            if (!this.svgTooltips[config_key].hideTimerSub) {
                this.svgTooltips[config_key].hideTimerSub.unsubscribe();
            }
            return;
        }
        this.svgTooltips[config_key].showTimerSub = timer(this.timeout).subscribe(() => {
                const tooltip_div = this.createTooltipDiv(this.svgTooltips[config_key].text);
                this.renderer.appendChild(this.clickies.nativeElement, tooltip_div);
                this.renderer.addClass(tooltip_div, this.svgTooltips[config_key].customClass);
                this.renderer.addClass(tooltip_div, 'tooltip-visible');
                if (with_color) {
                    this.renderer.addClass(tooltip_div, this.cloudState);
                }
                this.svgTooltips[config_key].containerVisible = true;
            }
        );
    }

    /**
     * Callback for mouseLeave event.
     * @param config_key
     */
    private mouseLeaveEvent(config_key: string): void {
        const diff = moment().diff(moment(this.svgTooltips[config_key].mouseInTime), 'ms');

        if (diff < this.timeout) {
            if (this.svgTooltips[config_key].showTimerSub) {
                this.svgTooltips[config_key].showTimerSub.unsubscribe();
            }
            return;
        }

        this.svgTooltips[config_key].hideTimerSub = timer(this.timeout - 200).subscribe(() => {
            const inserted = this.clickies.nativeElement.querySelector('.tooltip');
            this.renderer.addClass(inserted, 'tooltip-hidden');
            this.svgTooltips[config_key].hideAnimationSub = timer(200).subscribe(() => {
                this.renderer.removeChild(this.clickies.nativeElement, inserted);
                this.svgTooltips[config_key].containerVisible = false;
            });

        });
    }


}
